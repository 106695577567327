<script lang="ts">
    import ProgressBar from "$lib/components/ProgressBar.svelte";

    import ObjetDemande from "$lib/forms/ObjetDemande.svelte";
    import Banque from "$lib/forms/Banque.svelte";
    import ObjetPret from "$lib/forms/ObjetPret.svelte";
    import NbEmprunteurs from "$lib/forms/NbEmprunteurs.svelte";
    import Prets from "$lib/forms/Prets.svelte";
    import Assures from "$lib/forms/Assures.svelte";
    import {clear_storage} from "$lib/config";
    import Results from "$lib/forms/Results.svelte";
    import {onMount} from "svelte";
    import type {Simulation} from "$lib/api/models";
    import Navbar from "$lib/components/Navbar.svelte";

    let step = 1;
    let simulation: Partial<Simulation>;
    let isGTMWesur = false
    let isGTMPilliot = false

    onMount(() => {
        if (clear_storage) {
            localStorage.clear();
        }
        isGTMWesur = window.location.href.includes('wesur');
        isGTMPilliot = window.location.href.includes('pilliot');
    });
</script>

<svelte:head>
    <title>Omeros</title>
    <meta
            name="description"
            content="Optimisez votre assurance emprunteur et économisez"
    />
    {#if isGTMWesur}
        <!-- Google Tag Manager -->
        <script>(function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start':
                    new Date().getTime(), event: 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src =
                'https://metrics.wesur.fr/j8dq58vaq48g3h0.js?aw=' + i.replace(/^GTM-/, '') + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-KMZSNDJ');
        </script>
        <!-- End Google Tag Manager -->
    {/if}
</svelte:head>
{#if isGTMWesur}
    <Navbar/>
{/if}
<ProgressBar {step}></ProgressBar>

<div class="section">
    {#if step === 1}
        <ObjetDemande bind:step bind:simulation></ObjetDemande>
    {:else if step === 2}
        <Banque bind:step bind:simulation></Banque>
    {:else if step === 3}
        <ObjetPret bind:step bind:simulation></ObjetPret>
    {:else if step === 4}
        <NbEmprunteurs bind:step></NbEmprunteurs>
    {:else if step === 5}
        <Prets bind:step bind:simulation></Prets>
    {:else if step === 6}
        <Assures bind:step bind:simulation></Assures>
    {:else}
        <Results bind:step bind:simulation></Results>
    {/if}
</div>
{#if isGTMWesur}
    <!-- Google Tag Manager (noscript) -->
    <noscript>
        <iframe src="https://metrics.wesur.fr/ns.html?id=GTM-KMZSNDJ"
                height="0" width="0" style="display:none;visibility:hidden"></iframe>
    </noscript>
    <!-- End Google Tag Manager (noscript) -->
{/if}

<style lang="postcss">
    .section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 0.6;
    }
</style>
